<template>
  <!-- 系统安装 -->
  <div class="theSystem">
    <div class="tsBox1">
      <div class="tb1Box">
        <div class="tb1Text">
          <p class="tt1">极客狗装机大师</p>
          <p class="tt2">
            一键重装电脑，全年供应最稳定系统 让系统重装变得更省力
          </p>
          <div class="tt3">
            <p class="tt3p">支持：Win XP</p>
            &ensp;
            <img
              src="http://jkg.vhwkcl.cn/assets/img/winxp.png"
              alt=""
              class="tt3I"
            />
            <p class="tt3p">Win 7</p>
            <img
              src="http://jkg.vhwkcl.cn/assets/img/winxp.png"
              alt=""
              class="tt3I"
            />
            <p class="tt3p">Win 8</p>
            <img
              src="http://jkg.vhwkcl.cn/assets/img/winxp.png"
              alt=""
              class="tt3I"
            />
            <p class="tt3p">Win 10</p>
            <img
              src="http://jkg.vhwkcl.cn/assets/img/winxp.png"
              alt=""
              class="tt3I"
            />
          </div>
          <div class="tt4">立即观看</div>
          <p class="tt5">威有下载 查看软件教程</p>
        </div>
        <div class="tb1Img"></div>
      </div>
      <img
        src="http://jkg.vhwkcl.cn/assets/img/line-2.png"
        alt=""
        class="tb1Bg"
      />
    </div>
    <div class="tsBox2">
      <div class="tb2Box">
        <div class="t2bTop">
          <p class="t21">首款兼容原版装机工具</p>
          <p class="t22">原版系统，全面提升了系统的兼容性，稳定性，安全性</p>
        </div>
        <div class="t2bBox1">
          <div class="t21Left">
            <img
              src="http://jkg.vhwkcl.cn/assets/img/fun-org.gif"
              alt=""
              class="t21lImg"
            />
          </div>
          <div class="t21Right">
            <div class="t21r1">
              <img
                src="http://jkg.vhwkcl.cn/assets/img/sprite.png"
                alt=""
                class="tr1Icon"
              />
            </div>
            <p class="t21r2">一键智能重装原版系统</p>
            <p class="t21r3">
              零技术基础，突破英文障碍和笨重的光驱，初学者也能玩转电脑系统。
              <br />支持设备：笔记本、游戏本、台式电脑、一体机
            </p>
            <div class="t21r4">
              <ul>
                <li class="tr4Li" v-for="item in 3" :key="item">
                  <div class="t4l1">
                    <img
                      src="http://jkg.vhwkcl.cn/assets/img/sprite.png"
                      alt=""
                      class="t4lIcon"
                    />
                  </div>
                  <p class="t4l2">纯净、纯粹</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="t2bBox2">
          <div class="tb2Left">
            <div class="t2l1">
              <img
                src="http://jkg.vhwkcl.cn/assets/img/sprite.png"
                alt=""
                class="t21Icon"
              />
            </div>
            <p class="t2l2">全新智能U盘多分区</p>
            <p class="t2l3">UEFI与BIOS完美结合，一键操作，简单易用</p>
            <div class="t2l4">
              <ul>
                <li
                  class="t24Li"
                  v-for="item in 3"
                  :key="item"
                  @mousemove="showT2Img(item)"
                  :class="{ t2lActive: isT2 === item }"
                >
                  U盘模式{{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div class="tb2right">
            <img
              src="http://jkg.vhwkcl.cn/assets/img/fun-udisk.png"
              alt=""
              class="t2rImg"
              v-show="showT2i === 1"
            />
            <img
              src="http://jkg.vhwkcl.cn/assets/img/fun-local.png"
              alt=""
              class="t2rImg"
              v-show="showT2i === 2"
            />
            <img
              src="http://jkg.vhwkcl.cn/assets/img/fun-iso.png"
              alt=""
              class="t2rImg"
              v-show="showT2i === 3"
            />
          </div>
        </div>
        <div class="t2bBox3">
          <div class="tb3Left">
            <img
              src="http://jkg.vhwkcl.cn/assets/img/fun-backup.png"
              alt=""
              class="t3lImg"
            />
          </div>
          <div class="tb3Right">
            <div class="t3r1">
              <img
                src="http://jkg.vhwkcl.cn/assets/img/sprite.png"
                alt=""
                class="t3rIcon"
              />
            </div>
            <p class="t3r2">系统智能备份还原</p>
            <p class="t3r3">
              支持GPT与Win系统，快速备份还原，稳定可靠，完美兼容
            </p>
            <div class="t3r4">
              <ul>
                <li class="t34Li" v-for="item in 9" :key="item">
                  <img
                    src="http://jkg.vhwkcl.cn/assets/img/sprite.png"
                    alt=""
                    class="t3lIcon"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tsBox3">
      <div class="tb3Box">
        <div class="t3bTop">管理自如安全可靠</div>
        <div class="t3bList">
          <ul>
            <li class="t3lLi" v-for="item in 4" :key="item">
              <div class="t3l1">
                <img
                  src="http://jkg.vhwkcl.cn/assets/img/sprite.png"
                  alt=""
                  class="t31Icon"
                />
              </div>
              <p class="t3l2"></p>
              <p class="t3l3">简单傻瓜式操作</p>
              <p class="t3l4">无需光驱/无需U盘/智能一键 重装正版系统</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "theSystem",
  data() {
    return {
      showT2i: 1,
      isT2: 1,
    };
  },
  methods: {
    showT2Img(i) {
      this.showT2i = i;
      this.isT2 = i;
    },
  },
};
</script>

<style lang="less" scoped>
.theSystem {
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 87px;
  .tsBox1 {
    width: 100%;
    height: 880px;
    background: url("http://jkg.vhwkcl.cn/assets/img/index-wave.png") no-repeat
      bottom center;
    position: relative;
    overflow: hidden;
    .tb1Box {
      width: 855px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .tb1Text {
        width: 100%;
        height: 225px;
        margin-top: 20px;
        text-align: center;
        .tt1 {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 50px;
          font-weight: 900;
        }
        .tt2 {
          width: 100%;
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          margin-top: 10px;
        }
        .tt3 {
          width: 50%;
          height: 17px;
          line-height: 17px;
          margin: 0 auto;
          margin-top: 20px;
          .tt3p {
            max-width: 105px;
            height: 17px;
            float: left;
            margin-left: 10px;
          }
          .tt3I {
            width: 16px;
            height: 14px;
            float: left;
            margin-left: 10px;
            margin-top: 2px;
          }
        }
        .tt4 {
          width: 205px;
          height: 50px;
          line-height: 50px;
          color: white;
          font-size: 24px;
          text-align: center;
          margin: 0 auto;
          border-radius: 10px;
          margin-top: 20px;
          background-color: #4e74f0;
          cursor: pointer;
          transition: 0.5s;
        }
        .tt4:hover {
          background-image: linear-gradient(
            90deg,
            #4e74f0 0%,
            #00b7e6 33%,
            #00b7e6 66%,
            #00b7e6 100%
          );
        }
        .tt5 {
          width: 30%;
          height: 20px;
          line-height: 20px;
          text-align: center;
          color: gray;
          font-size: 14px;
          margin: 0 auto;
          margin-top: 10px;
        }
      }
      .tb1Img {
        width: 100%;
        height: 600px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 30px;
        z-index: 5;
      }
    }
    .tb1Bg {
      width: 6000px;
      height: 120px;
      z-index: 4;
      position: absolute;
      top: 300px;
      animation-name: leftTb1;
      animation-duration: 90s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    @keyframes leftTb1 {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(-4000px);
      }
    }
  }
  .tsBox2 {
    width: 100%;
    height: 1800px;
    box-shadow: 1px 1px 10px 1px gray;
    .tb2Box {
      width: 1210px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t2bTop {
        width: 100%;
        height: 60px;
        margin-top: 40px;
        text-align: center;
        .t21 {
          width: 100%;
          height: 35px;
          line-height: 35px;
          font-size: 35px;
        }
        .t22 {
          width: 100%;
          height: 15px;
          line-height: 15px;
          font-size: 15px;
          margin-top: 10px;
        }
      }
      .t2bBox1 {
        width: 100%;
        height: 377px;
        margin-top: 100px;
        .t21Left {
          width: 45%;
          height: 100%;
          float: left;
          .t21lImg {
            width: 100%;
            height: 100%;
          }
        }
        .t21Right {
          width: 45%;
          height: 100%;
          float: right;
          .t21r1 {
            width: 100px;
            height: 85px;
            overflow: hidden;
            position: relative;
            .tr1Icon {
              position: absolute;
              left: 2px;
              top: -100px;
            }
          }
          .t21r2 {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 30px;
            color: gray;
            margin-top: 50px;
          }
          .t21r3 {
            width: 100%;
            height: 40px;
            line-height: 20px;
            font-size: 14px;
            color: gray;
            margin-top: 30px;
          }
          .t21r4 {
            width: 100%;
            height: 70px;
            margin-top: 30px;
            ul {
              width: 100%;
              height: 100%;
              .tr4Li {
                width: 60px;
                height: 70px;
                float: left;
                .t4l1 {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  border: 1px solid #e8edfd;
                  margin: 0 auto;
                  overflow: hidden;
                  position: relative;
                  .t4lIcon {
                    position: absolute;
                    top: -185px;
                  }
                }
                .t4l2 {
                  width: 60px;
                  height: 12px;
                  line-height: 12px;
                  font-size: 12px;
                  color: #4e74f0;
                  margin-top: 8px;
                }
              }
              .tr4Li:nth-child(n + 2) {
                margin-left: 30px;
              }
              .tr4Li:hover {
                .t4l1 {
                  box-shadow: 1px 1px 10px 1px #e8edfd;
                  transition: 0.5s;
                }
              }
            }
          }
        }
      }
      .t2bBox2 {
        width: 100%;
        height: 375px;
        margin-top: 200px;
        .tb2Left {
          width: 45%;
          height: 100%;
          float: left;
          text-align: right;
          .t2l1 {
            width: 90px;
            height: 85px;
            position: relative;
            overflow: hidden;
            margin-left: 450px;
            .t21Icon {
              position: absolute;
              left: -80px;
              top: -100px;
            }
          }
          .t2l2 {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 30px;
            color: gray;
            margin-top: 30px;
          }
          .t2l3 {
            width: 100%;
            height: 20px;
            line-height: 20px;
            color: gray;
            margin-top: 30px;
          }
          .t2l4 {
            width: 252px;
            height: 38px;
            border-radius: 25px;
            border: 1px solid #4e74f0;
            margin-left: 290px;
            margin-top: 40px;
            ul {
              width: 98%;
              height: 90%;
              margin: 0 auto;
              margin-top: 2px;
              border-radius: 25px;
              display: flex;
              justify-content: space-around;
              .t24Li {
                text-align: center;
                width: 80px;
                height: 34px;
                line-height: 34px;
                font-size: 14px;
                color: #4e74f0;
              }
              .t2lActive {
                width: 85px;
                height: 34px;
                border-radius: 25px;
                background-color: #4e74f0;
                color: white;
              }
            }
          }
        }
        .tb2right {
          width: 45%;
          height: 100%;
          float: right;
          .t2rImg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .t2bBox3 {
        width: 100%;
        height: 409px;
        margin-top: 200px;
        .tb3Left {
          width: 45%;
          height: 100%;
          float: left;
          .t3lImg {
            width: 100%;
            height: 100%;
          }
        }
        .tb3Right {
          width: 45%;
          height: 100%;
          float: right;
          .t3r1 {
            width: 90px;
            height: 85px;
            overflow: hidden;
            position: relative;
            .t3rIcon {
              position: absolute;
              left: -155px;
              top: -100px;
            }
          }
          .t3r2 {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 30px;
            color: gray;
            margin-top: 50px;
          }
          .t3r3 {
            width: 100%;
            height: 40px;
            line-height: 20px;
            font-size: 14px;
            color: gray;
            margin-top: 30px;
          }
          .t3r4 {
            width: 100%;
            height: 138px;
            margin-top: 20px;
            margin-left: -20px;
            ul {
              width: 70%;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              .t34Li {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                border: 1px solid #e8edfd;
                margin-left: 20px;
                overflow: hidden;
                position: relative;
                .t3lIcon {
                  position: absolute;
                  top: -100px;
                  left: -255px;
                }
              }
              .t34Li:hover {
                box-shadow: 1px 1px 10px 1px #e8edfd;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
  }
  .tsBox3 {
    width: 100%;
    height: 500px;
    .tb3Box {
      width: 1210px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t3bTop {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 36px;
        margin-top: 80px;
      }
      .t3bList {
        width: 100%;
        height: 260px;
        margin-top: 50px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          .t3lLi {
            width: 20%;
            height: 100%;
            .t3l1 {
              width: 100px;
              height: 100px;
              margin: 0 auto;
              margin-top: 15px;
              position: relative;
              overflow: hidden;
              .t31Icon {
                position: absolute;
              }
            }
            .t3l2 {
              width: 24px;
              height: 4px;
              border-radius: 5px;
              background-color: #ffcd79;
              margin: 0 auto;
              margin-top: 20px;
              transition: 0.5s;
            }
            .t3l3 {
              width: 100%;
              height: 20px;
              line-height: 20px;
              text-align: center;
              font-size: 20px;
              margin-top: 30px;
            }
            .t3l4 {
              width: 100%;
              height: 40px;
              line-height: 20px;
              font-size: 14px;
              color: gray;
              margin-top: 30px;
            }
          }
          .t3lLi:hover {
            .t3l2 {
              width: 100px;
            }
          }
        }
      }
    }
  }
}
</style>
